package com.vladtulitu.website.models

enum class Section(
    val id: String,
    val title: String,
    val subtitle: String,
    val path: String
) {
    Home(
        id = "home",
        title = "home",
        subtitle = "",
        path = "#home"
    ),
    About(
        id = "about",
        title = "who am I?",
        subtitle = "What Do I Offer?",
        path = "#about"
    ),
    Service(
        id = "service",
        title = "services",
        subtitle = "I can help you with",
        path = "#service"
    ),
    Portfolio(
        id = "portfolio",
        title = "portfolio",
        subtitle = "My Work",
        path = "#portfolio"
    ),
    Experience(
        id = "experience",
        title = "experience",
        subtitle = "Work Experience",
        path = "#experience"
    ),
    Contact(
        id = "contact",
        title = "contact",
        subtitle = "Let's connect",
        path = "#contact"
    ),
    Achievements(
        id = "achievements",
        title = "achievements",
        subtitle = "Personal Achievements",
        path = "#achievements"
    )
}