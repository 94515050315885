package com.vladtulitu.website.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vladtulitu.website.models.Section
import com.vladtulitu.website.models.Theme
import com.vladtulitu.website.styles.NavigationContactItemStyle
import com.vladtulitu.website.styles.NavigationItemStyle
import org.jetbrains.compose.web.css.*

@Composable
fun Header(onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()

    Row(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint > Breakpoint.MD) 80.percent else 90.percent)
            .margin(bottom = 50.px),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        LeftSide(
            breakpoint = breakpoint,
            onMenuClicked = onMenuClicked
        )
        if (breakpoint > Breakpoint.MD) {
            RightSide()
        }
    }
}

@Composable
fun LeftSide(
    breakpoint: Breakpoint,
    onMenuClicked: () -> Unit
) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        if (breakpoint <= Breakpoint.MD) {
            FaBars(
                modifier = Modifier
                    .margin(top=30.px)
                    .color(Color.white)
                    .onClick {
                        onMenuClicked()
                    },
                size = IconSize.XL
            )
        }
        /*   Image(
               modifier = LogoStyle.toModifier(),
               src = Res.Image.logo,
               desc = "Logo Image"
           )

         */
    }
}

@Composable
fun RightSide() {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(r = 50.px)
            .backgroundColor(Theme.Secondary.rgb)
            .padding(all = 20.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        Section.values().take(6).forEach { section ->
            var linkModifier = NavigationItemStyle.toModifier()
                .padding(right = 30.px)
                .padding(8.px)
                .fontFamily()
                .fontSize(20.px)
                .fontWeight(FontWeight.Normal)
                .textDecorationLine(TextDecorationLine.None)

            if (section == Section.Contact) {
                // Apply a different style for Section.Contact
                linkModifier = NavigationContactItemStyle.toModifier().textDecorationLine(TextDecorationLine.None)
                    .fontFamily()
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Color.white)
                    .backgroundColor(Color.black)
                    .borderRadius(20.px)
            
                    .padding(leftRight = 8.px, topBottom = 6.px)
                    .margin(left = 14.px)
                    //.backgroundSize(BackgroundSize.Cover)

                    .alignItems(AlignItems.Center)
                    .justifyContent(JustifyContent.Center)
            }

            Link(
                modifier = linkModifier,
                path = section.path,
                text = section.title
            )
        }
    }
}