package com.vladtulitu.website.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vladtulitu.website.components.Header
import com.vladtulitu.website.components.SocialBar
import com.vladtulitu.website.models.Section
import com.vladtulitu.website.models.Theme
import com.vladtulitu.website.styles.MainButtonStyle
import com.vladtulitu.website.utils.Res
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun MainSection(onMenuClicked: () -> Unit) {
    Box(
        modifier = Modifier
            .id(Section.Home.id)
            .fillMaxWidth()
            .styleModifier {
                height(100.vh)
            },
        contentAlignment = Alignment.TopCenter
    ) {
        MainContent()
        Header(onMenuClicked = onMenuClicked)
    }
}

@Composable
fun MainContent() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Box(
            modifier = Modifier.fillMaxSize().padding(top = 120.px),
            contentAlignment = Alignment.Center
        ) {
            /*  ImageLeft(
                  modifier = Modifier
                      .styleModifier {
                          position(Position.Absolute)
                          left((-140).px)
                          bottom(0.px)
                          zIndex(1)  // set z-index to 1 for the image
                      }
              )

             */
            MainText(
                modifier = Modifier
                    .styleModifier {
                        zIndex(2)
                    },
                breakpoint = breakpoint
            )
            if (breakpoint >= Breakpoint.LG) {
                ImageRight(
                    modifier = Modifier
                        .styleModifier {
                            position(Position.Absolute)
                            right((-140).px)
                            bottom(0.px)
                            zIndex(1)
                        }
                )
            }
        }
        Spacer()
    }
}
/*
@Composable
fun ImageLeft(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier.fillMaxSize(50.percent),
        verticalArrangement = Arrangement.Bottom
    ) {
        Image(
            modifier = Modifier.fillMaxWidth(),
            src = Res.Image.img_main_left,
            desc = "Main Image"
        )
    }
}

 */

@Composable
fun ImageRight(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier.fillMaxSize(50.percent),
        verticalArrangement = Arrangement.Bottom
    ) {
        Image(
            modifier = Modifier.fillMaxWidth(),
            src = Res.Image.img_main_right,
            desc = "Main Image"
        )
    }
}

@Composable
fun MainText(modifier: Modifier = Modifier, breakpoint: Breakpoint) {

    Row(
        modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (breakpoint > Breakpoint.MD) {
            //   SocialBar()
        }
        Column(verticalArrangement = Arrangement.SpaceBetween, horizontalAlignment = Alignment.CenterHorizontally) {
            P(
                attrs = Modifier
                    .fontFamily()
                    .margin(topBottom = 0.px)
                    .fontSize(
                        when {
                            breakpoint >= Breakpoint.XL -> 100.px
                            breakpoint >= Breakpoint.LG -> 90.px
                            breakpoint >= Breakpoint.MD -> 80.px
                            breakpoint >= Breakpoint.SM -> 70.px
                            else -> 50.px
                        }
                    )
                    .fontWeight(FontWeight.Bold)
                    .color(Theme.Primary.rgb)
                    .toAttrs()
            ) {
                Text("Hey there 👋")
            }
            P(
                attrs = Modifier
                    .fontFamily()
                    .textAlign(TextAlign.Center)
                    .fontSize(
                        when {
                            breakpoint >= Breakpoint.XL -> 60.px
                            breakpoint >= Breakpoint.LG -> 50.px
                            breakpoint >= Breakpoint.MD -> 40.px
                            breakpoint >= Breakpoint.SM -> 30.px
                            else -> 30.px
                        }
                    )
                    .fontWeight(FontWeight.Bolder)
                    .color(Theme.Primary.rgb)
                    .toAttrs()
            ) {
                Text("I am Vlad Tulitu")
            }

            P(
                attrs = Modifier
                    .fontFamily()
                    .fontSize(
                        when {
                            breakpoint >= Breakpoint.XL -> 22.px
                            breakpoint >= Breakpoint.LG -> 22.px
                            breakpoint >= Breakpoint.MD -> 18.px
                            breakpoint >= Breakpoint.SM -> 18.px
                            else -> 16.px
                        }
                    )
                    .fontWeight(FontWeight.Bold)
                    .margin(top = 140.px)
                    .color(Theme.Primary.rgb)
                    .toAttrs()
            ) {
                Text("iOS & Android Developer")
            }
            P(
                attrs = Modifier
                    .fontFamily()
                    .fontSize(
                        when {
                            breakpoint >= Breakpoint.XL -> 16.px
                            breakpoint >= Breakpoint.LG -> 14.px
                            breakpoint >= Breakpoint.MD -> 18.px
                            breakpoint >= Breakpoint.SM -> 18.px
                            else -> 16.px
                        }
                    )
                    .fontWeight(FontWeight.Bold)
                    .color(Theme.Primary.rgb)
                    .toAttrs()
            ) {
                Text("Google Play Store Expert")
            }
            /*   P(
                   attrs = Modifier
                       .backgroundColor(Theme.Secondary.rgb)
                       .fontFamily()
                       .margin(bottom = 25.px)
                       .maxWidth(800.px)
                       .fontSize(
                           when {
                               breakpoint >= Breakpoint.XL -> 18.px  // extra-large: 1536px
                               breakpoint >= Breakpoint.LG -> 16.px  // large: 1200px
                               breakpoint >= Breakpoint.MD -> 14.px  // medium: 900px
                               breakpoint >= Breakpoint.SM -> 12.px  // small: 600px
                               else -> 16.px  // extra-small: 0px
                           }
                       )
                       .fontWeight(FontWeight.Bolder)
                       .color(Colors.Black)
                       .textAlign(TextAlign.Center)
                       .borderRadius(20.px) // Add this to give the background rounded corners
                       .toAttrs()
               ) {
                   Text("A dedicated and experienced Android Developer with a proven track record of creating successful applications. I bring to the table significant accomplishments, having achieved over 630,000 downloads and robust revenue on the Play Store since the beginning of 2020. Recently, I have expanded my skills to include iOS development, where I am actively learning and applying new knowledge to become proficient in this area as well.\n" +
                           "My comprehensive expertise covers the full mobile application development lifecycle, from the initial planning and research stages, through to development, successful launch, and post-launch maintenance.")
               }

             */
            Row() {
                Button(
                    attrs = MainButtonStyle.toModifier()
                        .height(54.px)
                        .margin(10.px)
                        .border(width = 0.px)
                        .borderRadius(r = 16.px)
                        .backgroundColor(Theme.Secondary.rgb)
                        .fontFamily()
                        .fontSize(20.px)
                        .fontWeight(FontWeight.Bolder)
                        .textAlign(TextAlign.Center)
                        .color(Colors.Black)
                        .cursor(Cursor.Pointer)
                        .toAttrs()
                ) {
                    Link(
                        modifier = Modifier
                            .color(Colors.Black)
                            .textDecorationLine(TextDecorationLine.None),
                        text = "About Me",
                        path = Section.About.path
                    )
                }
                Button(
                    attrs = MainButtonStyle.toModifier()
                        .height(54.px)
                        .margin(10.px)
                        .border(width = 0.px)
                        .fontSize(20.px)
                        .borderRadius(r = 16.px)
                        .fontWeight(FontWeight.Bolder)
                        .backgroundColor(Theme.Secondary.rgb)
                        .fontFamily()
                        .lineHeight(1)
                        .textAlign(TextAlign.Center)
                        .color(Colors.Black)
                        .cursor(Cursor.Pointer)
                        .toAttrs()
                ) {
                    Link(
                        modifier = Modifier
                            .color(Colors.Black)
                            .textDecorationLine(TextDecorationLine.None),
                        text = "My Work",
                        path = Section.Portfolio.path
                    )
                }
            }
            SocialBar(row = true)
        }
    }
}

