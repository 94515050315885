package com.vladtulitu.website.models

import com.vladtulitu.website.utils.Res

enum class Portfolio(
    val image: String,
    val title: String,
    val description: String,
    val link:String
) {
    One(
        image = Res.Image.coming_soon,
        title = "Kopilot",
        description = "Truck Navigation (Android/iOS)",
        link="https://play.google.com/store/apps/details?id=ro.seltronix.kopilot"
    ),
    Two(
        image = Res.Image.coming_soon,
        title = "DPI Changer",
        description = "Root/System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.dpimodifier"
    ),
    Three(
        image = Res.Image.coming_soon,
        title = "Resolution Changer",
        description = "Root/System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.resolutionchanger"
    ),
    Four(
        image = Res.Image.coming_soon,
        title = "DNS Flusher",
        description = "Root/System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.dnsflusher"
    ),
    Five(
        image = Res.Image.coming_soon,
        title = "Resolution Checker",
        description = "System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.abetterandroid.resolutionchecker"
    ),
    Six(
        image = Res.Image.coming_soon,
        title = "MitigationsOff",
        description = "Root/System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.abetterandroid.mitigationsoff"
    ),
    Seven(
        image = Res.Image.coming_soon,
        title = "Screen Information",
        description = "System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.screeninformation"
    )
    ,
    Eight(
        image = Res.Image.coming_soon,
        title = "HDR Checker",
        description = "System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.hdrchecker"
    )
    ,
    Nine(
        image = Res.Image.coming_soon,
        title = "Screen Information",
        description = "System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.screeninformation"
    )
    ,
    Ten(
        image = Res.Image.coming_soon,
        title = "Root Checker",
        description = "Root/System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.rootchecker"
    )
    ,
    Eleven(
        image = Res.Image.coming_soon,
        title = "AdBlock Detector",
        description = "System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.abetterandroid.adblockerdetector"
    ),
    Twelve(
        image = Res.Image.coming_soon,
        title = "Refresh Rate Checker",
        description = "System Tool (Android)",
        link="https://play.google.com/store/apps/details?id=com.theviciousgames.refreshratechecker"
    )
}