import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.vladtulitu.website.pages.HomePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.NavigationItemStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.BottomNavigationItemStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.NavigationContactItemStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.LogoStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.SocialLinkStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.MainButtonStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.MainImageStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.AboutImageStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.AboutTextStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.BackToTopButtonStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.ServiceCardStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.PortfolioSectionStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.PortfolioArrowIconStyle)
        ctx.theme.registerComponentStyle(com.vladtulitu.website.styles.InputStyle)
        com.vladtulitu.website.updateTheme(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "Vlad Tulitu")
        ) { com.vladtulitu.website.MyApp {
              router.renderActivePage { renderWithDeferred { it() } }
        } }
    }
}
