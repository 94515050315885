package com.vladtulitu.website.models

import com.vladtulitu.website.utils.Constants.LOREM_IPSUM_LONG


enum class Experience(
    val number: String,
    val jobPosition: String,
    val description: String,
    val company: String,
    val from: String,
    val to: String
) {
    First(
        number = "02",
        jobPosition = "Sole Android Developer",
        description = "The core functionality of Kopilot centers around intelligent route planning based on the specifications of the trucks and daily updated fuel prices, allowing transporters to consistently run profitable routes.As the sole Android developer, led the complete overhaul of the \"Kopilot\" application, handling all aspects from design, coding, testing, debugging, to deployment. Undertook this project single-handedly and successfully transformed it using native Kotlin, significantly enhancing user experience and efficiency.",
        company = "DKV & Smart Diesel",
        from = "May 2022",
        to = "Present",
    ),
    Second(
        number = "01",
        jobPosition = "Self-Published Indie Developer",
        description = "Over three years as an Indie Developer, I've transformed my passion for innovation into a thriving presence on the Google Play Store. My apps, inspired by user interests and needs, have achieved over 700,000 downloads and thousands of positive reviews, testifying to the quality of my work.",
        company = "TheViciousGames & aBetterAndroid & Controversialz",
        from = "October 2019",
        to = "Present",
    )
}