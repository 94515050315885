package com.vladtulitu.website.sections

import androidx.compose.runtime.Composable

import com.vladtulitu.website.styles.NavigationItemStyle

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.vladtulitu.website.components.SocialBar
import com.vladtulitu.website.models.Section
import com.vladtulitu.website.models.Theme
import com.vladtulitu.website.styles.BottomNavigationItemStyle

import com.vladtulitu.website.utils.Constants.SECTION_WIDTH
import com.vladtulitu.website.utils.Res
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun FooterSection() {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(SECTION_WIDTH.px)
            .padding(top = 100.px)
            ,
        contentAlignment = Alignment.Center
    ) {
        FooterContent()
    }
}

@Composable
fun FooterContent() {
    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier
            .margin(bottom=20.px)
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 100.percent
                else 90.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SocialBar(row = true)

            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                if (breakpoint > Breakpoint.MD) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .backgroundColor(Color.black)
                            .padding(all = 20.px),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Center
                    ) {
                        FooterMenu()
                    }
                }
            }
        }
    }

@Composable
fun FooterMenu(row: Boolean = true) {
    Section.values().take(6).forEach { section ->
        Link(
            modifier = BottomNavigationItemStyle.toModifier()
                .padding(right = 30.px)
                .padding(8.px)
                .fontFamily()
                .fontSize(20.px)
                .fontWeight(FontWeight.Normal)
                .color(Color.white)
                .textDecorationLine(TextDecorationLine.None),
            path = section.path,
            text = section.title
        )
    }
}
