package com.vladtulitu.website.models


import com.vladtulitu.website.utils.Constants.LOREM_IPSUM_SHORTEST
import com.vladtulitu.website.utils.Res

enum class Service(
    val icon: String,
    val imageDesc: String,
    val title: String,
    val description: String
) {
    Android(
        icon = Res.Icon.android,
        imageDesc = "Android Icon",
        title = "Android Development",
        description = "Crafting modern Android applications tailored to your specific needs."
    ),
    IOS(
        icon = Res.Icon.apple,
        imageDesc = "Apple Icon",
        title = "iOS Development",
        description = "Designing and building intuitive iOS applications for your personal or business needs."
    ),
    Web(
        icon = Res.Icon.web,
        imageDesc = "Desktop Icon",
        title = "Landing Pages",
        description = "Creating simple, clean, and effective landing pages for products or personal websites, tailored to highlight key features and attract target audiences."
    ),
    Design(
        icon = Res.Icon.design,
        imageDesc = "Pen Icon",
        title = "UX/UI Design",
        description = "Innovative UX/UI designs enhancing user interactions and experiences with your app."
    ),
    Business(
        icon = Res.Icon.business,
        imageDesc = "Chart Icon",
        title = "App Analysis",
        description = "Insightful app analytics, revealing crucial information about user behaviour and revenue streams."
    ),
    SEO(
        icon = Res.Icon.seo,
        imageDesc = "Megaphone Icon",
        title = "Play Store Marketing",
        description = "Promoting your app with effective marketing strategies, boosting its visibility on Play Store and App Stores."
    )
}