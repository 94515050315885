package com.vladtulitu.website.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.minHeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.Page
import com.vladtulitu.website.components.BackToTopButton
import com.vladtulitu.website.components.OverflowMenu
import com.vladtulitu.website.models.Theme
import com.vladtulitu.website.sections.*
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.vh

@Page
@Composable
fun HomePage() {
    var menuOpened by remember { mutableStateOf(false) }
    Box(modifier = Modifier.fillMaxSize().background(Theme.Background.rgb).overflow(Overflow.Hidden)){
        Column(
            modifier = Modifier.fillMaxSize().backgroundColor(Theme.Background.rgb).overflow(Overflow.Hidden).styleModifier {
                minHeight(100.vh)
            },
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            MainSection(onMenuClicked = { menuOpened = true })
            AboutSection()
            ServiceSection()
            PortfolioSection()
            AchievementsSection()
            ExperienceSection()
            ContactSection()
            FooterSection()
        }
        BackToTopButton()
        if (menuOpened) {
          OverflowMenu(onMenuClosed = { menuOpened = false })
        }
    }
}
